// App.js
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../Constants";
import HeaderComponent from "../components/HeaderComponent";
import SubCategoryTable from "../components/SubCategoryTable";

function GetAllSubCategoryComponent() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(BASE_URL + "/api/subcategory/getAll")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      <HeaderComponent />
      <div className="App">
        <h1>Sub Category Data</h1>
        <SubCategoryTable data={data} />
      </div>
    </>
  );
}

export default GetAllSubCategoryComponent;
