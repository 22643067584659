import React, { Component } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import { BASE_URL } from '../Constants';
import { Link } from 'react-router-dom';

class HeaderComponent extends Component {
    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <Link className="navbar-brand" to="/admin">Gangadwar Admin Panel </Link>
                    </div>
                    <ul className="nav navbar-nav">
                        <li ><Link to="/admin">Home&nbsp;</Link></li>
                        <li><Link to="/admin/insert/category">Insert Category&nbsp;</Link></li>
                        <li><Link to="/admin/insert/subcategory">Insert Sub Category&nbsp;</Link></li>
                        <li><Link to="/admin/content">Insert Content&nbsp;</Link></li>
                        <li><Link target='_blank' to={`${BASE_URL}/api/common/reindex`} rel="noreferrer">Refresh Content&nbsp;</Link></li>
                        <li><Link target='_blank' to={`${BASE_URL}/api/common/refresh`} rel="noreferrer">Refresh All Backends&nbsp;</Link></li>
                    </ul>
                </div>
            </nav>
        )
    }
}

export default HeaderComponent
