// DataTable.js
import React from "react";
const DataTable = ({ dataAll, id }) => {
  console.log("scid", id);

  const data = dataAll.content;
  return (
    <>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>ID</th>
            <th>Title Primary</th>
            <th>Content Primary</th>
            {/* <th>Sub Category</th> */}
            <th>Display Flag</th>
            <th>action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.contentId}>
              <td>{item.contentId}</td>

              <td>{item.titlePrimary}</td>

              <td>
                {item.contentPrimary &&
                  item.contentPrimary.split("\n").map((item1, key1) => (
                    <span key={key1}>
                      {item1}
                      <br />
                    </span>
                  ))}
              </td>
              {/* <td>{id}</td> */}
              <td>{item.displayFlag}</td>
              <td>
                {/* <a href="/">Edit</a> */}
                <a
                  href={`/admin/content/${item.contentId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Edit
                </a>
                <br />
                <a
                  href={`/content/${item.contentId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Preview
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default DataTable;
