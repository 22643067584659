import React from "react";
// import './PaginationControls.css'; // You can style it as needed

const PaginationControls = ({
  page,
  size,
  totalElements,
  onPageChange,
  onSizeChange,
}) => {
  const totalPages = Math.ceil(totalElements / size);

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < totalPages) {
      onPageChange(newPage);
    }
  };

  const handleNextButtonClick = () => {
    if (page < totalPages - 1) {
      handlePageChange(page + 1);
    }
  };

  return (
    <div className="pagination-controls">
      <button onClick={() => handlePageChange(page - 1)} disabled={page === 0}>
        Previous
      </button>
      <span className="page-info">
        Page {page + 1} of {totalPages}
      </span>
      <button
        onClick={handleNextButtonClick}
        disabled={page === totalPages - 1}
      >
        Next
      </button>
      <span className="page-size-label">Page Size:</span>
      <select
        value={size}
        onChange={(e) => onSizeChange(Number(e.target.value))}
      >
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
      </select>
    </div>
  );
};

export default PaginationControls;
