// App.js
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../Constants";
import DataTable from "../components/DataTable";
import HeaderComponent from "../components/HeaderComponent";
import PaginationControls from "../components/PaginationComponent";

function GetAllContent() {
  const { scid } = useParams();
  const [id, setId] = useState({});
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0); // Initialize the page state
  const [size, setSize] = useState(10); // Initialize the page size state
  const [totalElements, setTotalElements] = useState(0);

  useEffect(() => {
    axios
      .get(BASE_URL + `/api/content/get/${scid}`)
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
        setTotalElements(response.data.totalElements);
        setId(scid);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("An error occurred while fetching data.");
        setIsLoading(false);
      });
  }, [scid]);

  useEffect(() => {
    // Define a function to fetch data based on the page and size
    const fetchData = async () => {
      try {
        const response = await axios.get(
          BASE_URL + `/api/content/get/${scid}`,
          {
            params: { page, size },
          }
        );
        setData(response.data);
        setIsLoading(false);
        setTotalElements(response.data.totalElements);
        setId(scid);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the fetchData function
  }, [page, scid, size]);

  // Log the data to inspect its contents
  console.log("Data:", data);
  console.log("scid", scid);
  return (
    <>
      <HeaderComponent />
      {isLoading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <>
          <h1>Content by Sub Category</h1>
          <PaginationControls
            page={page}
            size={size}
            totalElements={totalElements}
            onPageChange={setPage} // Pass the setPage function to handle page changes
            onSizeChange={setSize} // Pass the setSize function to handle size changes
          />
          <DataTable dataAll={data} id={id} />
        </>
      )}
    </>
  );
}

export default GetAllContent;
