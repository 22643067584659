import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../Constants";
import "../css/form.css";

const initialFormData = {
  titlePrimary: "",
  contentPrimary: "",
  titleSecondary: "",
  contentSecondary: "",
  displayFlag: "Y",
  showSecondary: "N",
  subCategoryImage: null,
  subCategory: {
    subCategoryId: "",
  },
  file: null,
  displayOrder: 0,
  bookPgNo: 0,
};

function SubCategoryForm() {
  const { id } = useParams();
  const [formData, setFormData] = useState(initialFormData);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (id) {
      // Make an API request here only if 'id' is available
      fetch(BASE_URL + `/api/content/nocache/${id}`)
        .then((response) => response.json())
        .then((data) => {
          setFormData(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [id]);

  useEffect(() => {
    // Fetch data from the API
    if (options.length === 0) {
      fetch(BASE_URL + "/api/subcategory/getAll")
        .then((response) => response.json())
        .then((data) => setOptions(data))
        .catch((error) => console.error("API Error:", error));
    }
  }, [options]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Check if the input field name contains a dot (indicating a subfield)
    if (name.includes(".")) {
      const [parentField, subField] = name.split(".");
      setFormData({
        ...formData,
        [parentField]: {
          ...formData[parentField],
          [subField]: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("FormData:", formData);

    try {
      const response = await fetch(BASE_URL + "/api/content", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("API Response:", data);
        // Handle the API response data as needed.
        alert("Content Added/Updated Successfully");
        // setFormData(initialFormData);
        //reload page
        window.location.reload();
      } else {
        console.error("API Error:", response.statusText);
        // Handle API error, e.g., show an error message to the user.
        alert("Please check fields");
      }
    } catch (error) {
      console.error("API Error:", error.message);
      // Handle network errors or other exceptions.
      alert("Something went wrong");
    }
  };

  return (
    <>
      <div className="row register-form">
        <div className="col-md-8 offset-md-2">
          <form className="custom-form" onSubmit={handleSubmit}>
            <h1>Content Form</h1>
            <div className="row form-group">
              <div className="col-sm-4 label-column">
                <label className="col-form-label">Title Primary</label>
              </div>
              <div className="col-sm-6 input-column">
                <input
                  className="form-control"
                  type="text"
                  value={formData.titlePrimary.split(".").pop()}
                  name="titlePrimary"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-4 label-column">
                <label className="col-form-label">Content Primary&nbsp;</label>
              </div>
              <div className="col-sm-6 input-column">
                <textarea
                  className="form-control"
                  type="text"
                  value={formData.contentPrimary}
                  name="contentPrimary"
                  onChange={handleInputChange}
                ></textarea>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-4 label-column">
                <label className="col-form-label">Display Secondary </label>
              </div>
              <div className="col-sm-4 input-column">
                <select
                  className="form-select"
                  name="showSecondary"
                  value={formData.showSecondary}
                  onChange={(e) => {
                    handleInputChange(e); // Call handleInputChange to update state
                  }}
                >
                  <option key="N" value="N">
                    No
                  </option>
                  <option key="Y" value="Y">
                    Yes
                  </option>
                  <option key="R" value="R">
                    Under Review
                  </option>
                </select>
              </div>
            </div>

            <div className="row form-group">
              <div className="col-sm-4 label-column">
                <label className="col-form-label">Title Secondary</label>
              </div>
              <div className="col-sm-6 input-column">
                <input
                  className="form-control"
                  type="text"
                  value={formData.titleSecondary}
                  name="titleSecondary"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-4 label-column">
                <label className="col-form-label">
                  Content Secondary&nbsp;
                </label>
              </div>
              <div className="col-sm-6 input-column">
                <textarea
                  className="form-control"
                  type="text"
                  value={formData.contentSecondary}
                  name="contentSecondary"
                  onChange={handleInputChange}
                ></textarea>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-4 label-column">
                <label className="col-form-label">Select Sub Category </label>
              </div>
              <div className="col-sm-4 input-column">
                <select
                  className="form-select"
                  name="subCategory.subCategoryId"
                  value={formData.subCategory.subCategoryId}
                  onChange={(e) => {
                    console.log("Selected Value:", e.target.value); // Log selected value
                    handleInputChange(e); // Call handleInputChange to update state
                  }}
                >
                  <option value="">Select an option</option>
                  {options.map((option) => (
                    <option
                      key={option.subCategoryId}
                      value={option.subCategoryId}
                    >
                      {option.titlePrimary}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* Page No and Display Order */}

            <div className="row form-group">
              <div className="col-sm-4 label-column">
                <label className="col-form-label">Book Page No</label>
              </div>
              <div className="col-sm-6 input-column">
                <input
                  className="form-control"
                  type="text"
                  value={formData.bookPgNo}
                  name="bookPgNo"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row form-group">
              <div className="col-sm-4 label-column">
                <label className="col-form-label">Display Order</label>
              </div>
              <div className="col-sm-6 input-column">
                <input
                  className="form-control"
                  type="text"
                  value={formData.displayOrder}
                  name="displayOrder"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row form-group">
              <div className="col-sm-4 label-column">
                <label className="col-form-label">Display Flag </label>
              </div>
              <div className="col-sm-4 input-column">
                <select
                  className="form-select"
                  name="displayFlag"
                  value={formData.displayFlag}
                  onChange={(e) => {
                    console.log("Selected Value:", e.target.value); // Log selected value
                    handleInputChange(e); // Call handleInputChange to update state
                  }}
                >
                  <option key="Y" value="Y">
                    Yes
                  </option>
                  <option key="N" value="N">
                    No
                  </option>
                  <option key="R" value="R">
                    Under Review
                  </option>
                </select>
              </div>
            </div>

            <button className="btn btn-light submit-button" type="submit">
              Submit Form
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
export default SubCategoryForm;
