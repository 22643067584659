import React from "react";
import { BASE_URL, TEMP_DEMO } from "../Constants";
import "./css/HomePage.css";
import { Link } from "react-router-dom";
const ShowAllContentBySubCategoryComponent = ({ dataAll, subcategory }) => {
  const data = dataAll;
  // console.log("data", data);

  return (
    <>
      <div className="container py-4 py-xl-5">
        <div className="row mb-5">
          <div className="col-md-8 col-xl-6 text-center mx-auto text-center card-title">
            <h2>{subcategory.titlePrimary}</h2>

            <p className="card-text center">
              {subcategory.contentPrimary.split("\n").map((item1, key1) => {
                return (
                  <span key={key1}>
                    {item1}
                    <br />
                  </span>
                );
              })}
            </p>
            <img
              src={
                subcategory.subCategoryImage
                  ? `${BASE_URL}/static/subcategoryimg/${subcategory.subCategoryImage}`
                  : `${BASE_URL}/static/subcategoryimg/default.png`
              }
              alt={`${subcategory.titlePrimary}`}
              className="mx-auto d-block customimage center w-75"
            />
          </div>
        </div>
        <div class="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3 p-2">
          {Array.isArray(data)
            ? data.map((item) => (
                <>
                  <h4 style={{ "text-align": "center" }}>
                    <Link
                      className="content-link"
                      to={`${TEMP_DEMO}/content/${item.contentId}`}
                    >
                      {item.titlePrimary}
                    </Link>
                  </h4>
                </>
              ))
            : null}
        </div>
      </div>
    </>
  );
};

export default ShowAllContentBySubCategoryComponent;
