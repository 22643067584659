import React from "react";
import { isMobileApp } from "../Constants";

const FooterComponent = () => {
  const currentYear = new Date().getFullYear(); // Get the current year

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <hr />
            <p className="text-center sub-color">
              {!isMobileApp ? (
                <a
                  href="https://play.google.com/store/apps/details?id=com.sarvesh.gangadwar"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>Download App on Google Play! </b> <br />
                </a>
              ) : (
                ""
              )}
              &copy; {currentYear} Gangadwar Bhajnawali. All rights reserved.
              <br />
              <a href="/page/about-us">
                Designed and Developed by <b>Biyani</b> Family
              </a>
              <br />
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
