import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { BASE_URL } from "../Constants";
import ShowAllContentBySubCategoryComponent from "./ShowSubCategoryComponent";
import FooterComponent from "./FooterComponent";

function ShowAllContentBySubCategory() {
  const { scid } = useParams();

  const { data, isLoading, isError } = useQuery(
    ["contentBySubcategory", scid],
    async () => {
      const [contentResponse, subcategoryResponse] = await Promise.all([
        axios.get(BASE_URL + `/api/content/sccache/${scid}`),
        axios.get(BASE_URL + `/api/subcategory/${scid}`),
      ]);

      return {
        content: contentResponse.data,
        subcategory: subcategoryResponse.data,
      };
    },
    {
      staleTime: 60000, // Set the stale time to 60 seconds (adjust as needed)
      cacheTime: 600000, // Set the cache time to 10 minutes (adjust as needed)
      onSuccess: (data) => {
        data.subcategory.titleSecondary
          ? (document.title =
              data.subcategory.titlePrimary +
              " | " +
              data.subcategory.titleSecondary +
              " | Gangadwar.in | Gangadwar Bhajnawali")
          : (document.title =
              data.subcategory.titlePrimary +
              " | Gangadwar.in | Gangadwar Bhajnawali");
      },
    }
  );

  return (
    <>
      {isLoading ? (
        <p>Loading...</p>
      ) : isError ? (
        <p>Error: An error occurred while fetching data.</p>
      ) : (
        <>
          <ShowAllContentBySubCategoryComponent
            dataAll={data.content}
            id={data.id}
            subcategory={data.subcategory}
          />

          <FooterComponent />
        </>
      )}
    </>
  );
}

export default ShowAllContentBySubCategory;
