import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { BASE_URL } from "../Constants";
import "../css/form.css";

const initialFormData = {
  titlePrimary: "",
  contentPrimary: "",
  titleSecondary: "",
  contentSecondary: "",
  displayFlag: "",
  showSecondary: "",
  categoryImage: null,
};
function CategoryForm() {
  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("FormData:", formData);
    try {
      const response = await fetch(BASE_URL + "/api/category", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("API Response:", data);
        // Handle the API response data as needed.
        alert("Category Added Successfully");
        setFormData(initialFormData);
      } else {
        console.error("API Error:", response.statusText);
        // Handle API error, e.g., show an error message to the user.
        alert("Please check fields");
      }
    } catch (error) {
      console.error("API Error:", error.message);
      // Handle network errors or other exceptions.
      alert("Something went wrong");
    }
  };

  return (
    <>
      <div className="row register-form">
        <div className="col-md-8 offset-md-2">
          <form className="custom-form" onSubmit={handleSubmit}>
            <h1>Category Form</h1>
            <div className="row form-group">
              <div className="col-sm-4 label-column">
                <label className="col-form-label">Title Primary</label>
              </div>
              <div className="col-sm-6 input-column">
                <input
                  className="form-control"
                  type="text"
                  value={formData.titlePrimary}
                  name="titlePrimary"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-4 label-column">
                <label className="col-form-label">Content Primary&nbsp;</label>
              </div>
              <div className="col-sm-6 input-column">
                <textarea
                  className="form-control"
                  type="text"
                  value={formData.contentPrimary}
                  name="contentPrimary"
                  onChange={handleInputChange}
                ></textarea>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-4 label-column">
                <label className="col-form-label">Display Secondary </label>
              </div>
              <div className="col-sm-4 input-column">
                <select
                  className="form-select"
                  name="showSecondary"
                  value={formData.showSecondary}
                  onChange={(e) => {
                    handleInputChange(e); // Call handleInputChange to update state
                  }}
                >
                  <option key="N" value="N">
                    No
                  </option>
                  <option key="Y" value="Y">
                    Yes
                  </option>
                  <option key="R" value="R">
                    Under Review
                  </option>
                </select>
              </div>
            </div>

            <div className="row form-group">
              <div className="col-sm-4 label-column">
                <label className="col-form-label">Title Secondary</label>
              </div>
              <div className="col-sm-6 input-column">
                <input
                  className="form-control"
                  type="text"
                  value={formData.titleSecondary}
                  name="titleSecondary"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-4 label-column">
                <label className="col-form-label">
                  Content Secondary&nbsp;
                </label>
              </div>
              <div className="col-sm-6 input-column">
                <textarea
                  className="form-control"
                  type="text"
                  value={formData.contentSecondary}
                  name="contentSecondary"
                  onChange={handleInputChange}
                ></textarea>
              </div>
            </div>
            {/* Image Upload Code here */}

            <button className="btn btn-light submit-button" type="submit">
              Submit Form
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
export default CategoryForm;
