import React from "react";
const subCategoryTable = ({ data }) => {
  return (
    <>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>ID</th>
            <th>Title Primary</th>
            <th>Content Primary</th>
            <th>action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.subCategoryId}>
              <td>{item.subCategoryId}</td>

              <td>{item.titlePrimary}</td>

              <td>
                {item.contentPrimary &&
                  item.contentPrimary.split("\n").map((item1, key1) => (
                    <span key={key1}>
                      {item1}
                      <br />
                    </span>
                  ))}
              </td>

              <td>
                <a
                  href={`/admin/content/list/${item.subCategoryId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Show Content
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default subCategoryTable;
