import {useLocation } from "react-router-dom";
import { useEffect } from "react";
function NotFoundPage() {
    const location = useLocation();
  
    useEffect(() => {
      // Update title with 404 and current path
      document.title = `Gangadwar.in | 404 - Page Not Found | ${location.pathname}`;
    }, [location.pathname]);
  
    return (
      <div>
        <h2>404 Page not found</h2>
        <p>Go back to <a href="/">Home</a></p>
      </div>
    );
  
}

export default NotFoundPage