import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/js/bootstrap.js";
import React, { useEffect } from "react";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { BASE_URL, TEMP_DEMO } from "../Constants";
import "./css/Nav.css";
import logo from "./images/gangadwar_web_logo.webp";

const fetchSubcategories = async () => {
  const response = await axios.get(`${BASE_URL}/api/subcategory/getNav`);
  return response.data;
};

const NavBarComponent = () => {
  const {
    data: subcategories = [],
    isLoading,
    isError,
  } = useQuery("subcategories", fetchSubcategories);

  useEffect(() => {
    // Optionally handle loading or error states here
  }, [isLoading, isError]);

  return (
    <Navbar expand="lg" collapseOnSelect>
      <Navbar.Brand>
        <Link className="nav-link" to={`${TEMP_DEMO}/`}>
          <img src={logo} alt="Gangadwar" className="navlogo" />
        </Link>
      </Navbar.Brand>
      <Link to={`${TEMP_DEMO}/`} className="navbar-brand">
        Gangadwar Bhajnawali
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
          <Link className="nav-link" to={`${TEMP_DEMO}/`}>
            Home
          </Link>
          <Link className="nav-link" to="/search">
            Search
          </Link>
          <Link className="nav-link" to="/page/about-us">
            About
          </Link>
          <NavDropdown title="Bhajans" id="basic-nav-dropdown">
            {subcategories.map((subcategory) => (
              <Link
                className="dropdown-item"
                key={subcategory.subCategoryId}
                to={`${TEMP_DEMO}/subcategory/${subcategory.subCategoryId}`}
              >
                {subcategory.titlePrimary}
              </Link>
            ))}
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBarComponent;
