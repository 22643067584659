import React, { useState } from "react";
import { BASE_URL, TEMP_DEMO } from "../Constants";
import FooterComponent from "./FooterComponent";

function SearchPage() {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [initialTerm, setInitialTerm] = useState("");

  const handleSearch = async (term) => {
    try {
      const response = await fetch(
        BASE_URL + `/api/content/search?term=${term}`
      );
      if (response.ok) {
        const data = await response.json();
        setSearchResults(data.response.docs);
        setInitialTerm(term);
      } else {
        console.error("Failed to fetch data from the API");
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(searchQuery);
    }
  };

  return (
    <>
      <div className="container py-4 py-xl-5 max-height">
        <div className="row mb-5">
          <div className="col-md-8 col-xl-6 text-center mx-auto text-center card-title ">
            {initialTerm && <h1>Showing results for "{initialTerm}"</h1>}
            <div className="form-group mx-sm-3 mb-2 d-flex">
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                className="form-control"
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyUp={handleKeyPress}
              />
              <button
                className="btn btn-primary mb-2"
                onClick={() => handleSearch(searchQuery)}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div class="row gy-4 row-cols-1 p-2">
          {Array.isArray(searchResults)
            ? searchResults.map((item) => (
                <h4 style={{ textAlign: "center" }} key={item.contentId}>
                  <a
                    className="content-link"
                    href={`${TEMP_DEMO}/content/${item.contentId}`}
                  >
                    {item.titlePrimary}
                  </a>
                </h4>
              ))
            : null}
        </div>
      </div>

      <FooterComponent />
    </>
  );
}

export default SearchPage;
