import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../Constants";

import "./css/HomePage.css";
import FooterComponent from "./FooterComponent";

function ShowStaticPage() {
  const { pagename } = useParams(); // Get the route parameter

  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    fetch(BASE_URL + `/api/common/${pagename}`)
      .then((response) => response.json())
      .then((data) => setPageData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, [pagename]);
  const renderHtml = (htmlString) => {
    document.title =
      pageData.titlePrimary + " | Gangadwar.in | Gangadwar Bhajnawali";
    const replacedHtml = htmlString.replace(/{{BASE_URL}}/g, BASE_URL);
    console.log(replacedHtml);
    return { __html: replacedHtml };
  };
  return (
    <>
      <div>
        {pageData ? (
          <>
            <p
              dangerouslySetInnerHTML={renderHtml(pageData.contentPrimary)}
            ></p>
            <FooterComponent />
          </>
        ) : (
          <p>Loading data...</p>
        )}
      </div>
    </>
  );
}

export default ShowStaticPage;
