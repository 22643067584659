import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { BASE_URL } from "../Constants";
import ShowOneContentComponent from "./ShowOneContentComponent";
import FooterComponent from "./FooterComponent";

export function ShowOneContentService() {
  const { id } = useParams();

  const { data, isLoading, isError } = useQuery(
    ["content", id],
    async () => {
      const response = await axios.get(BASE_URL + `/api/content/${id}`);
      return response.data;
    },
    {
      staleTime: 60000, // Set the stale time to 60 seconds (adjust as needed)
      onSuccess: (data) => {
        let title = data.titlePrimary.split(".").pop();
        data.titleSecondary
          ? (document.title =
              title +
              " | " +
              data.titleSecondary +
              " Lyrics | Gangadwar.in | Gangadwar Bhajnawali")
          : (document.title =
              title +
              " Lyrics | Gangadwar.in | Gangadwar Bhajnawali");
      },
    }
  );
  
  return (
    <>
      {isLoading ? (
        <p>Loading...</p>
      ) : isError ? (
        <p>Error fetching data</p>
      ) : (
        <>
          <ShowOneContentComponent data={data}  />
          <FooterComponent />
        </>
      )}
    </>
  );
}
