import React from "react";
import "./css/HomePage.css";
import { Link } from "react-router-dom";
import { BASE_URL, TEMP_DEMO } from "../Constants";


const HomePageComponent = ({ data }) => {
  console.log("data", data);

  return (
    <>
      <div className="container py-4 py-xl-5">
        <div className="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3">
          {data.map((item) => (
            <>
              <div className="col">
                <Link
                  to={`${TEMP_DEMO}/subcategory/${item.subCategoryId}`}
                  className="card "
                >
                  <div class="customimage-container">
                    <img
                      src={
                        item.subCategoryImage
                          ? `${BASE_URL}/static/subcategoryimg/${item.subCategoryImage}`
                          : `${BASE_URL}/static/subcategoryimg/default.png`
                      }
                      alt={`${item.titlePrimary}`}
                      className="card-img-top w-75 d-block fit-cover customimage"
                    />
                  </div>
                  <div className="card-body p-4 text-center">
                    <h4 className="card-title ">{item.titlePrimary}</h4>
                    <p className="card-text">
                      {item.contentPrimary &&
                        item.contentPrimary.split("\n").map((item1, key1) => (
                          <span key={item1.subCategoryId}>
                            {item1}
                            <br />
                          </span>
                        ))}
                    </p>
                  </div>
                </Link>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default HomePageComponent;
