import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../Constants";
import "../css/form.css";

const initialFormData = {
  titlePrimary: "",
  contentPrimary: "",
  titleSecondary: "",
  contentSecondary: "",
  displayFlag: "Y",
  showSecondary: "N",
  subCategoryImage: null,
  category: {
    categoryId: "",
  },
};
function SubCategoryForm() {
  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Check if the input field name contains a dot (indicating a subfield)
    if (name.includes(".")) {
      const [parentField, subField] = name.split(".");
      setFormData({
        ...formData,
        [parentField]: {
          ...formData[parentField],
          [subField]: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const [options, setOptions] = useState([]); // State to store the dropdown options

  useEffect(() => {
    // Fetch data from the API
    fetch(BASE_URL + "/api/category/getAll")
      .then((response) => response.json())
      .then((data) => setOptions(data))
      .catch((error) => console.error("API Error:", error));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("FormData:", formData);
    try {
      const response = await fetch(BASE_URL + "/api/subcategory", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("API Response:", data);
        // Handle the API response data as needed.
        alert("Sub Category Added Successfully");
        setFormData(initialFormData);
      } else {
        console.error("API Error:", response.statusText);
        // Handle API error, e.g., show an error message to the user.
        alert("Please check fields");
      }
    } catch (error) {
      console.error("API Error:", error.message);
      // Handle network errors or other exceptions.
      alert("Something went wrong");
    }
  };

  return (
    <>
      <div className="row register-form">
        <div className="col-md-8 offset-md-2">
          <form className="custom-form" onSubmit={handleSubmit}>
            <h1>Sub Category Form</h1>
            <div className="row form-group">
              <div className="col-sm-4 label-column">
                <label className="col-form-label">Title Primary</label>
              </div>
              <div className="col-sm-6 input-column">
                <input
                  className="form-control"
                  type="text"
                  value={formData.titlePrimary}
                  name="titlePrimary"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-4 label-column">
                <label className="col-form-label">Content Primary&nbsp;</label>
              </div>
              <div className="col-sm-6 input-column">
                <textarea
                  className="form-control"
                  type="text"
                  value={formData.contentPrimary}
                  name="contentPrimary"
                  onChange={handleInputChange}
                ></textarea>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-4 label-column">
                <label className="col-form-label">Display Secondary </label>
              </div>
              <div className="col-sm-4 input-column">
                <select
                  className="form-select"
                  name="showSecondary"
                  value={formData.showSecondary}
                  onChange={(e) => {
                    handleInputChange(e); // Call handleInputChange to update state
                  }}
                >
                  <option key="N" value="N">
                    No
                  </option>
                  <option key="Y" value="Y">
                    Yes
                  </option>
                  <option key="R" value="R">
                    Under Review
                  </option>
                </select>
              </div>
            </div>

            <div className="row form-group">
              <div className="col-sm-4 label-column">
                <label className="col-form-label">Title Secondary</label>
              </div>
              <div className="col-sm-6 input-column">
                <input
                  className="form-control"
                  type="text"
                  value={formData.titleSecondary}
                  name="titleSecondary"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-4 label-column">
                <label className="col-form-label">
                  Content Secondary&nbsp;
                </label>
              </div>
              <div className="col-sm-6 input-column">
                <textarea
                  className="form-control"
                  type="text"
                  value={formData.contentSecondary}
                  name="contentSecondary"
                  onChange={handleInputChange}
                ></textarea>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-4 label-column">
                <label className="col-form-label">Select Category </label>
              </div>
              <div className="col-sm-4 input-column">
                <select
                  className="form-select"
                  name="category.categoryId"
                  value={formData.category.categoryId}
                  onChange={(e) => {
                    console.log("Selected Value:", e.target.value); // Log selected value
                    handleInputChange(e); // Call handleInputChange to update state
                  }}
                >
                  <option value="">Select an option</option>
                  {options.map((option) => (
                    <option key={option.categoryId} value={option.categoryId}>
                      {option.titlePrimary}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* <div className="row form-group">
                            <div className="col-sm-4 label-column"><label className="col-form-label" >Content Secondary&nbsp;</label></div>
                            <div className="col-sm-6 input-column">
                                <textarea
                                    className="form-control" type="text"
                                    value={formData.contentSecondary}
                                    name="contentSecondary"
                                    onChange={handleInputChange}
                                ></textarea>
                            </div>
                        </div> */}

            <div className="row form-group">
              <div className="col-sm-4 label-column">
                <label className="col-form-label">Display Flag </label>
              </div>
              <div className="col-sm-4 input-column">
                <select
                  className="form-select"
                  name="displayFlag"
                  value={formData.displayFlag}
                  onChange={(e) => {
                    console.log("Selected Value:", e.target.value); // Log selected value
                    handleInputChange(e); // Call handleInputChange to update state
                  }}
                >
                  {/* <option value="">Select an option</option> */}

                  <option key="Y" value="Y">
                    Yes
                  </option>
                  <option key="N" value="N">
                    No
                  </option>
                  <option key="R" value="R">
                    Under Review
                  </option>
                </select>
              </div>
            </div>

            {/* Image Upload Code here */}

            <button className="btn btn-light submit-button" type="submit">
              Submit Form
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
export default SubCategoryForm;
