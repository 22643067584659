/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import YouTube from "react-youtube";
import { BASE_URL } from "../Constants";
import { Share } from "@capacitor/share";
import { CgShare } from "react-icons/cg";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import axios from "axios";

const ShowOneContentComponent = ({ data }) => {
  const renderVideoPlayer = () => {
    if (!data || !data.videoPath) {
      return null; // or any fallback UI if data.videoPath is null or undefined
    }

    const isMediaPath = data.videoPath.startsWith("/media/");

    return (
      <div className="mb-5 mt-3">
        {isMediaPath ? (
          <video controls className="w-50">
            <source src={data.videoPath} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <YouTube
            videoId={data.videoPath}
            style={{ width: "100%", justifyContent: "center", display: "flex" }}
          />
        )}
      </div>
    );
  };

  let title = data.titlePrimary.split(".").pop();
  const handleShare = async () => {
    try {
      await Share.share({
        title: "Bhajan Lyrics - " + title,
        text: "Bhajan Lyrics - " + title + "\n",
        url: `https://gangadwar.in/content/${data.contentId}`,
        dialogTitle: "Share with Others",
      });
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };
  const handlePlay = () => {
    if (!hasPlayed && data && data.contentId) {
      console.log("onPlay");
      setHasPlayed(true);
      axios.get(BASE_URL + `/api/getmore/audio/${data.contentId}`);
    }
  };
  const handleEnded = () => {
    setHasPlayed(false); // Reset the play state when the audio ends
  };
  const [hasPlayed, setHasPlayed] = useState(false); // State to track if the audio has been played

  return (
    <div className="container" style={{ marginTop: "2rem" }}>
      <div className="col-md-12">
        <div className="d-flex justify-content-between align-items-center">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item" aria-current="page">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <a href={`/subcategory/${data.subCategory.subCategoryId}`}>
                  {data.subCategory.titlePrimary}
                </a>
              </li>
            </ol>
          </nav>
          <a
            href="#"
            onClick={handleShare}
            className="d-flex align-items-center text-decoration-none share-button"
          >
            <b className="ml-auto light-color">
              <span className="mr-2">
                <CgShare />
              </span>
              &nbsp;Share
            </b>
          </a>
        </div>
      </div>
      <div className="row mt-4 mb-2">
        <div className="col">
          <h1 className="card-title center mt-3">{title}</h1>
        </div>
      </div>
      {/* Custom Image */}
      {data.imagePath ? (
        <div className="content-image center m-3">
          <img
            src={`${BASE_URL}${data.imagePath}`}
            className=""
            alt="loading issue"
          />
        </div>
      ) : (
        <></>
      )}
      <p className="card-text center">
        {data.contentPrimary.split("\n").map((item1, key1) => {
          return (
            <span key={key1}>
              {item1}
              <br />
            </span>
          );
        })}
      </p>
      <div className="row " style={{ justifyContent: "center" }}>
        {/* Custom Audio */}
        {data.audioPath ? (
          <AudioPlayer
            autoPlay={false}
            preload="auto"
            src={`${BASE_URL}${data.audioPath}`}
            onPlay={handlePlay}
            onEnded={handleEnded}
            showSkipControls={false}
            showJumpControls={true}
            className="custom-audio-player mt-3"
          />
        ) : (
          <></>
        )}
      </div>
      {/* <div className="" >  */}
      {/* Custom Video */}
      {renderVideoPlayer()}
      {/* </div> */}
      {data.showSecondary === "Y" &&
        data.contentSecondary &&
        data.videoPath == null &&
        data.audioPath != null && <hr className="my-4" />}

      {data.showSecondary === "Y" ? (
        <>
          <div className="row">
            <div className="col">
              <h1 className="card-title center">{data.titleSecondary}</h1>
            </div>
          </div>

          <p className="card-text center">
            {data.contentSecondary.split("\n").map((item1, key1) => {
              return (
                <span key={key1}>
                  {item1}
                  <br />
                </span>
              );
            })}
          </p>
        </>
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default ShowOneContentComponent;
